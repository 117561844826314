





























































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "privacy-protocol"
})
export default class privacyProtocol extends Vue {}
